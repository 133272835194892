<template>
  <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search"
    :onFinish='pageSearchChange' layout='inline'>
    <a-form-item label="生码单号" name='order_sn'>
      <a-input v-model:value="search.order_sn" style="width:250px" placeholder="请输入生码单号"></a-input>
    </a-form-item>
    <a-form-item label="充值时间" name=''>
      <a-range-picker valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChange" />
    </a-form-item>
    <a-form-item>
      <a-button type="primary" htmlType='submit'>搜索</a-button>
    </a-form-item>
  </a-form>
  <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data'>
  </a-table>
  <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper :current="search.page"
    :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange"
    :show-total="total => `总数: ${total} `" />
</template>
<script>
  import { ref, onMounted, inject, reactive, toRefs } from "vue";
  import { message, Modal } from "ant-design-vue";
  import { $iscode } from "@/utils/app";
  import { getGenerationDetails, } from "@/api/brand";
  // 初始化默认筛选项数值
  let defSearch = {
    start_time: "",
    end_time: "",
    page: 1,
    limit: 5,
    order_sn: '',
  };
  export default {
    components: {},
    props: {
      codeId: {
        type: Number || String
      }
    },
    setup(props) {
      // // 初始化 分页信息和筛选项信息
      let search = ref({ ...defSearch });
      // 列表数据和列头配置
      let data = ref([]);
      const state = reactive({
        total: 1,
        listLoading: false,
      });
      let columns = [

        {
          title: "公司名称",
          dataIndex: "brand_company",
          key: "brand_company",
        },
        {
          title: "生码单号",
          dataIndex: "order_sn",
          key: "order_sn",
        },
        {
          title: "批次号",
          dataIndex: "key",
          key: "key",
        },
        // {
        //   title: "套数",
        //   dataIndex: "company",
        //   key: "company",
        // },
        {
          title: "生码数量",
          dataIndex: "code_num",
          key: "code_num",
        },
        {
          title: "生码时间",
          dataIndex: "create_time",
          key: "create_time",
        },
      ];
      const $router = inject("$router");
      const $Modal = inject("$Modal");
      // 页面筛选项搜索
      const pageSearchChange = () => {
        search.value = { ...search.value, page: 1 };
        initData(search.value);
      };
      // 分页当前页切换
      const pageCurrentChange = (page, pageSize) => {
        search.value.page = page;
        initData(search.value);
      };
      // 分页当前页显示多少条切换
      const pageSizeChange = (current, size) => {
        search.value.page = 1;
        search.value.limit = size;
        initData(search.value);
      };
      // 时间筛选
      const onChange = (e) => {
        if (e.length > 0) {
          search.value.start_time = e[0];
          search.value.end_time = e[1];
        } else {
          search.value.start_time = "";
          search.value.end_time = "";
        }
        //   initData(search.value);
      };
      const initData = async (values) => {
        state.listLoading = true;
        data.value = [];
        try {
          let res = await getGenerationDetails({ ...values, brand_id: props.codeId, operator_type: 1 }).then((res) => res.data);
          state.listLoading = false;
          if ($iscode(res)) {
            data.value = res.data.data;
            state.total = res.data.total;
          } else {
            message.error(res.msg);
          }
        } catch (e) {
          state.listLoading = false;
        }
      };

      onMounted(() => {
        initData(search.value);
      });

      return {
        ...toRefs(state),
        search,
        data,
        columns,
        pageSearchChange,
        pageCurrentChange,
        pageSizeChange,
        onChange,
      };
    },
  };
</script>