<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <SmallSquare content="用给品牌商充值码量、查看品牌商已经生码的数量和未生码的数量." class="m-b-10" />
      <a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search" :onFinish='pageSearchChange' layout='inline'>
        <a-form-item label="关键词" name='keywords'>
          <a-input v-model:value="search.keywords" style="width:250px" placeholder="请输入品牌商编号/品牌商账号"></a-input>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" htmlType='submit'>搜索</a-button>
        </a-form-item>
      </a-form>
      <a-table :pagination='false' :loading='listLoading' bordered rowKey='id' :columns='columns' :data-source='data' class="tableFixBox" :scroll="{ x: 'max-content'}">
        <template v-slot:action="{ record }">
          <a v-has:codeRecharge="()=>codeRecharge(record)" href="javascript:;" class="m-r-20">充值</a>
          <a v-has:codeGenerationDetails="()=>codeGenerationDetails(record)" href="javascript:;" class="m-r-20">生码明细</a>
          <a v-has:RechargeDetails="()=>RechargeDetails(record)" href="javascript:;" class="m-r-20">充值明细</a>

        </template>
      </a-table>
      <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
    </a-card>
  </a-layout>
  <!-- 充值 -->
  <a-modal v-model:visible="codeRechargeVisible" title="码量充值" @ok="codeRechargecomfirm" centered :confirm-loading="confirmLoading" destroyOnClose>
    <a-form ref="ruleForm" :model="form" :label-col="{span:4}" :wrapper-col="{span:20}">
      <a-form-item ref="brand_id" label="品牌商编号" name="brand_id">
        {{form.brand_id}}
      </a-form-item>
      <a-form-item ref="company" label="公司名称" name="company">
        {{form.company}}
      </a-form-item>
      <a-form-item label="充值码量">
        <a-input v-model:value="code_num" placeholder="请输入充值码量"></a-input>
      </a-form-item>
    </a-form>
  </a-modal>
  <!-- 生码明细-->
  <a-modal v-model:visible="GenerationVisible" title="生码明细" :footer="null" centered destroyOnClose :width="1200">
    <CodeGenerationDetails :codeId='codeId'></CodeGenerationDetails>
  </a-modal>
  <!-- 充值明细-->
  <a-modal v-model:visible="RechargeVisible" title="充值明细" :footer="null" centered destroyOnClose :width="1200">
    <RechargeDetails :codeId='codeId'></RechargeDetails>
  </a-modal>
</template>
<script>
// import { DownOutlined,QuestionCircleFilled,PlusOutlined } from "@ant-design/icons-vue";
import { ref, onMounted, inject, reactive, toRefs, h } from "vue";
import { message, Modal } from "ant-design-vue";
import { $iscode } from "@/utils/app";
import RechargeDetails from "./RechargeDetails";
import CodeGenerationDetails from "./codeGenerationDetails";
import { getBrandtCodeList, BrandtCodeAdd } from "@/api/brand";
// 初始化默认筛选项数值
let defSearch = {
  keywords: "",
  status: "",
  page: 1,
  limit: 10,
};
export default {
  components: {
    RechargeDetails,
    CodeGenerationDetails,
  },
  setup(props) {
    // // 初始化 分页信息和筛选项信息
    let search = ref({ ...defSearch });
    // 列表数据和列头配置
    let data = ref([]);
    const form = ref({ brand_id: "", company: "" });
    const state = reactive({
      total: 1,
      listLoading: false,
      codeRechargeVisible: false,
      GenerationVisible: false,
      RechargeVisible: false,
      codeId: "",
      confirmLoading: false,
      code_num: "",
    });
    let columns = [
      {
        title: "品牌商编号",
        dataIndex: "brand_id",
        key: "brand_id",
      },
      {
        title: "公司名称",
        dataIndex: "company",
        key: "company",
      },
      {
        title: "品牌商账号",
        dataIndex: "account",
        key: "account",
      },
      {
        title: "码量",
        dataIndex: "code_num_all",
        key: "code_num_all",
      },
      {
        title: "已生码数量",
        dataIndex: "code_num_generate",
        key: "code_num_generate",
      },
      {
        title: "剩余码量",
        dataIndex: "code_num_generated",
        key: "code_num_generated",
      },
      {
        title: "操作",
        key: "action",
        width: 240,
        fixed:"right",
        slots: { customRender: "action" },
      },
    ];
    const $router = inject("$router");
    const $Modal = inject("$Modal");
    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, size) => {
      search.value.page = 1;
      search.value.limit = size;
      initData(search.value);
    };

    const initData = async (values) => {
      state.listLoading = true;
      data.value = [];
      try {
        let res = await getBrandtCodeList(values).then((res) => res.data);
        state.listLoading = false;
        if ($iscode(res)) {
          data.value = res.data.data;
          state.total = res.data.total;
        } else {
          message.error(res.msg);
        }
      } catch (e) {
        state.listLoading = false;
      }
    };

    onMounted(() => {
      initData(search.value);
    });

    //充值
    const codeRecharge = (e) => {
      state.code_num = "";
      state.codeId = e.brand_id;
      form.value.brand_id = e.brand_id;
      form.value.company = e.company;
      state.codeRechargeVisible = true;
    };
    //充值确定
    const codeRechargecomfirm = async (e) => {
      try {
        let res = await BrandtCodeAdd({
          brand_id: form.value.brand_id,
          code_num: state.code_num,
        }).then((res) => res.data);
        state.listLoading = false;
        if ($iscode(res)) {
          initData(search.value);
          state.codeRechargeVisible = false;
          message.success(res.msg);
        } else {
          message.error(res.msg);
        }
      } catch (e) {
        state.listLoading = false;
      }
    };

    //生码明细
    const codeGenerationDetails = async (e) => {
      state.codeId = e.brand_id;
      state.GenerationVisible = true;
    };
    //充值明细
    const RechargeDetails = async (e) => {
      state.codeId = e.brand_id;
      state.RechargeVisible = true;
    };

    return {
      ...toRefs(state),
      search,
      data,
      columns,
      pageSearchChange,
      pageCurrentChange,
      pageSizeChange,
      codeRecharge,
      codeGenerationDetails,
      RechargeDetails,
      codeRechargecomfirm,
      form,
    };
  },
};
</script>
